import React, { useEffect } from "react";
import { useStyles } from "./ArticleShow.style";
import MastheadLayout from "../../../components/Masterhead/Layouts/Layouts";
import Footer from "../../../components/Footer/Footer";
import Layouts from "../../../components/Article/Layouts/Layouts";
import RevContentWidget from "../../../components/RevContentWidget/RevContentWidget";

const ArticleShow = (props) => {
  const { master_head_info, town_info, rev_content } = props || {};
  const classes = useStyles();

  return (
    <>
      <div className={classes.master_head_container}>
        <MastheadLayout {...master_head_info} />
      </div>
      <Layouts props={props} />
      {rev_content && <RevContentWidget revContent={rev_content} />}
      <Footer town={town_info} options={{ noMarginTop: true }} />
    </>
  );
};

export default ArticleShow;
