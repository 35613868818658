import React from "react";
import clsx from "clsx";
import Grid from "../../../../../common/Grid/Grid";
import VideoAd from "../../../../Ad/VideoAd";
import Ad from "../../../../Ad/Ad";
import { useStyles } from "./TopAds.style";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";

const TopAds = ({
  withWhiteBorders,
  ad_targets,
  video_ad_content,
  bullseye_ad_count,
  variant,
}) => {
  
  const mediumScreen = screenWidthIsLowerThan(1025);
  const classes = useStyles();
  let containerWidth = mediumScreen ? 12 : 4;

  if (variant === "default") {
    containerWidth = mediumScreen ? 12 : 5;
  }

  return (
    <Grid
      item
      xs={12}
      md={containerWidth}
      lg={containerWidth}
      classes={{
        root: clsx(
          classes.top_ads,
          withWhiteBorders && classes.white_borders,
          variant === "default" && classes.width_layout_default
        ),
      }}
    >
      {video_ad_content?.show_video_ad && Object.keys(video_ad_content).length > 0 && (
        <VideoAd videoAd={video_ad_content} />
      )}

      <Ad
        ad_unit={video_ad_content?.show_locked_bullseye ? "locked_bullseye" : `tap_bullseye_00${bullseye_ad_count}`}
        size={[300, 250]}
        targets={ad_targets}
        element_id="tap_bullseye_in_sidebar"
      />
    </Grid>
  );
};

export default TopAds;
