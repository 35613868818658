import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SignUpNewsletter from "../SignUpNewsletter/SignUpNewsletter";
import { useStyles } from "./ArticleBody.style";
import clsx from "clsx";
import { randomString } from "../../utils/random";
import Ad from "../Ad/Ad";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import { BODY_CONTAINER_SELECTOR } from "../../utils/constants/trinityAudioConstants";
import { NYVT_MEDIA_SLUG } from "../../utils/constants/licenseConstants";

const BULLSEYE_TEXT_TO_REPLACE = "**BULLSEYE";
const STANDARD_TEXT_TO_REPLACE = "**STANDARD";
const TAP_BULLSEYE = "tap_bullseye_001";
const TAP_BULLSEYE_2 = "tap_bullseye_002";
const TAP_BULLSEYE_3 = "tap_bullseye_003";
const STANDARD = "desktop_standard_001";
const SIGNUP_SCRIPT_TEXT = "SIGNUPSCRIPT";
const BULLSEYE_SIZE = [300, 250];
const STANDARD_SIZE = [300, 100];

const ArticleBody = ({
  text,
  column,
  isAdFree,
  subscribeToContent,
  isTown,
  isArticlePreview,
  isPremium,
  adTargets,
}) => {
  const classes = useStyles();
  const getAdSizeByType = (type) => {
    if (
      type === TAP_BULLSEYE ||
      type === TAP_BULLSEYE_2 ||
      type === TAP_BULLSEYE_3
    ) {
      return BULLSEYE_SIZE;
    }
    return STANDARD_SIZE;
  };
  const bullseyes = [TAP_BULLSEYE, TAP_BULLSEYE_2, TAP_BULLSEYE_3];

  const paragraphWithAd = (paragraph, adType, position) => {
    let adUnit;
    const splittedTextByAdType = paragraph.split(adType);

    if (adType === BULLSEYE_TEXT_TO_REPLACE) {
      adUnit = bullseyes.shift();
    } else {
      adUnit = STANDARD;
    }

    if (position === 1) {
      return paragraphWithAd(paragraph, STANDARD_TEXT_TO_REPLACE, 0);
    }

    return splittedTextByAdType.map((splittedTextForAd, index) => {
      const formattedTextForAd = (
        <div
          key={`div-random-${randomString(5)}`}
          className={clsx(classes.paragraph)}
          dangerouslySetInnerHTML={{
            __html: splittedTextForAd,
          }}
        />
      );
      if (index === 1) {
        return formattedTextForAd;
      }

      return [
        formattedTextForAd,
        <div
          key={`div-random-${randomString(5)}`}
          className={
            adType === BULLSEYE_TEXT_TO_REPLACE
              ? classes.bullseye_container
              : classes.standard_container
          }
        >
          <Ad
            ad_unit={adUnit}
            size={getAdSizeByType(adUnit)}
            targets={adTargets}
            element_id={`${adUnit}-ad`}
          />
        </div>,
      ];
    });
  };

  const printBody = () => {
    const splittedTextBySignUp = text.split(SIGNUP_SCRIPT_TEXT);

    if (splittedTextBySignUp.length > 1) {
      const body = splittedTextBySignUp.map((splittedText, index) => {
        const splittedTextByBullseye = splittedText.split(
          BULLSEYE_TEXT_TO_REPLACE
        );

        const formattedText = (
          <div
            key={`div-formatted-text-${randomString(5)}`}
            className={clsx(classes.paragraph)}
            dangerouslySetInnerHTML={{ __html: splittedText }}
          />
        );
        if (index === 0 && subscribeToContent?.type) {
          return [
            formattedText,
            <div className={classes.mobile_newsletter_sing_up_container}>
              <Typography
                level="t1_text_1"
                color="gray"
                italic
                className={classes.show_only_in_mobile}
              >
                {labels.STORY_CONTINUES_BELOW_NEWSLETTER_SIGN_UP}
              </Typography>
              {subscribeToContent?.slug != NYVT_MEDIA_SLUG && (
                <SignUpNewsletter
                  isArticlePreview={isArticlePreview}
                  isTown={isTown}
                  subscribeToContent={subscribeToContent}
                  isPremium={isPremium}
                  isArticle={true}
                />
              )}
            </div>,
          ];
        }

        if (!isAdFree && splittedTextByBullseye.length > 2) {
          return splittedTextByBullseye.map(
            (splittedTextForBullseye, index) => {
              if (index == 0) {
                return paragraphWithAd(
                  splittedTextForBullseye,
                  BULLSEYE_TEXT_TO_REPLACE,
                  index
                );
              }
              if (index == 1 && !isAdFree) {
                const splittedTextByStandard = splittedTextForBullseye.split(
                  STANDARD_TEXT_TO_REPLACE
                );
                let subBody = [];
                let subParagraph = [
                  paragraphWithAd(
                    splittedTextByStandard[0],
                    STANDARD_TEXT_TO_REPLACE,
                    0
                  ),
                  paragraphWithAd(
                    splittedTextByStandard[1],
                    BULLSEYE_TEXT_TO_REPLACE,
                    0
                  ),
                ];
                return subBody.concat(subParagraph);
              }

              return (
                <div
                  key={`div-formatted-text-${randomString(5)}`}
                  className={clsx(classes.paragraph)}
                  dangerouslySetInnerHTML={{ __html: splittedTextForBullseye }}
                />
              );
            }
          );
        }
        if (!isAdFree && splittedTextByBullseye.length > 1) {
          return splittedTextByBullseye.map(
            (splittedTextForBullseye, index) => {
              return paragraphWithAd(
                splittedTextForBullseye,
                BULLSEYE_TEXT_TO_REPLACE,
                index
              );
            }
          );
        }

        return formattedText;
      });

      //Replaces body.flat() due to incompatibility.
      return body.reduce((acc, val) => acc.concat(val), []);
    } else if (splittedTextBySignUp.length === 1) {
      const splittedTextByBullseye = text.split(BULLSEYE_TEXT_TO_REPLACE);

      const body = splittedTextByBullseye.map(
        (splittedTextForBullseye, index) => {
          const formattedText = (
            <div
              key={`div-formatted-text-${randomString(5)}`}
              className={clsx(classes.paragraph)}
              dangerouslySetInnerHTML={{ __html: splittedTextForBullseye }}
            />
          );
          if (!isAdFree && splittedTextByBullseye.length >= 1) {
            return paragraphWithAd(
              splittedTextForBullseye,
              BULLSEYE_TEXT_TO_REPLACE,
              index
            );
          }
          return formattedText;
        }
      );
      //Replaces body.flat() due to incompatibility.
      return body.reduce((acc, val) => acc.concat(val), []);
    }
  };

  const VerificationForColumnArticle = () => {
    const VerificationBioBody = () => {
      if (column?.hide_disclaimer === false && column?.description) {
        return (
          <div
            className={classes.paragraph}
            dangerouslySetInnerHTML={{ __html: column?.description }}
          ></div>
        );
      }
      return "";
    };

    return (
      <>
        <VerificationBioBody />
      </>
    );
  };

  return (
    <div className={BODY_CONTAINER_SELECTOR}>
      {printBody().map((bodyPart, index) => (
        <Fragment key={`body-${index}`}>{bodyPart}</Fragment>
      ))}
      {VerificationForColumnArticle()}
    </div>
  );
};

ArticleBody.propTypes = {
  text: PropTypes.any,
  column: PropTypes.object,
  isAdFree: PropTypes.bool,
  subscribeToContent: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.number,
  }),
  isArticlePreview: PropTypes.bool,
  isTown: PropTypes.bool,
  isPremium: PropTypes.bool,
  adTargets: PropTypes.shape({
    towns: PropTypes.arrayOf(PropTypes.string),
    sections: PropTypes.arrayOf(PropTypes.string),
    sponsor_display: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ArticleBody;
